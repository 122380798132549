.match-radio-group {
  .ant-radio-button-wrapper {
    border-radius: 16px !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
    margin-right: 16px;
    margin-top: 8px;

    &::before {
      content: none !important;
    }
  }
}
