@import './variables.css';
@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
@layer utilities {
  .line-clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.bg-to-b {
  @apply bg-gradient-to-b from-primary-200 to-primary-600 !important;
}

.bg-to-r {
  @apply bg-gradient-to-r from-primary-200 to-primary-600 !important;
}

.bg-to-r:hover,
.bg-to-b:hover {
  @apply to-primary-200 !important;
}

.flex-center {
  @apply flex items-center justify-center !important;
}

body {
  height: auto !important;
}
