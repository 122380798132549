.match-form {
  .ant-form-item {
    width: 100%;
    min-height: 37px;
    border-radius: 16px;
    box-shadow: 2px 4px 16px 2px rgba(255, 94, 78, 0.08);
    background: white;
    border: 1px solid var(--coral);
    overflow: hidden;

    .ant-form-item-label {
      flex: auto !important;
      max-width: 90px !important;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      label {
        color: #e73d2c;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .ant-form-item-control {
      flex: 1 !important;
    }
    .anticon-down {
      color: #e73d2c !important;
    }
    .ant-input,
    .ant-select,
    .ant-cascader-picker-label {
      color: #4e4e4e;
    }
    .ant-cascader-picker-label {
      text-align: left;
      padding-left: 21px;
    }
  }
  input {
    border: none;
    box-shadow: none !important;
    padding-left: 23px;
  }
  textarea {
    border: none;
    resize: none;
    box-shadow: none !important;
    padding-left: 23px;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-select {
    width: 100%;
    .ant-select-selector {
      width: 100%;
      border: none !important;
      box-shadow: none !important;
      padding-left: 23px !important;
    }
    .ant-select-selection-item {
      text-align: left;
    }
    .ant-select-selection-placeholder {
      text-align: left;
    }
    .ant-select-selection-item-remove {
      display: flex;
      align-items: center;
    }
  }
  .ant-select-multiple {
    height: 100%;
    display: flex;
    align-items: center;

    .ant-select-selection-item {
      padding: 0px 3px;
    }
    .ant-select-selection-placeholder {
      left: 22px;
    }
  }

  @media (min-width: 768px) {
    .ant-form-item {
      min-height: 50px;

      .ant-form-item-label {
        max-width: 120px !important;
        label {
          font-size: 16px;
        }
      }
      .ant-input,
      .ant-select,
      .ant-cascader-picker-label {
        font-size: 18px;
      }
    }
  }
}
