.match-silder {
  .ant-slider-track {
    background: linear-gradient(to right, #ff4c3a 16%, #e43d58);
  }
  .ant-slider-handle {
    background: #e73d2c;
    border-color: #e73d2c;
  }
  .ant-slider-rail {
    background: #f8eeed;
  }
}
