.match-checkbox-group {
  .ant-checkbox {
    opacity: 0;
    position: absolute;
  }
  .ant-checkbox-wrapper {
    border-radius: 16px !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
    margin-top: 8px;
    margin-left: 0px !important;
    margin-right: 10px;
    padding: 0 10px;
    height: 30px;
    display: inline-flex;
    align-items: center;

    &.ant-checkbox-wrapper-checked {
      color: #fff;
      background: #fb5050;
      border-color: #fb5050;
    }
  }
}
