.match-select {
  .ant-select-selector {
    border-radius: 16px !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
  }
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: var(--primary-color);
    text-align: center;
  }
}
